import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Chatbot script tab`}</h2>
    <h2>{`Main chat tree - Actions`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Group name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`What to expect?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Advanced`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specialized actions for complex logic, integrations, or custom scripting to enhance chatbot capabilities.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ask`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Prompts users for input, collects responses, and processes answers for decision-making. Example: Card list, Engagement Cloud Satisfaction Survey, Options form URL, etc.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authentication`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Manages user authentication.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C2C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enables voice call initiation from the chatbot, connecting users.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Get information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Connecting data to Facebook.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Google analytics`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tracks user interactions within the chatbot.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Live Chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers conversations to a live operator while maintaining context and session continuity.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Natural Langauge Processing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enhances chatbot understanding by interpreting user intent and extracting meaningful data.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Naviagtion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specialized actions about navigation. Example: Delay next action, or finish chatbot, etc.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OCR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Integration with Azure Vision.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OpenAI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Integrates AI-driven conversational models for more dynamic, intelligent, and context-aware interactions.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Payments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Handles transactions, enabling secure payment processing within the chatbot experience.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Retrieves relevant results from a contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UI Path`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Automates workflows by integrating with RPA (Robotic Process Automation) for backend task execution.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Whatsapp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Connecting data to Whatsapp.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Write`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show image or text to user. Example: text or HTML or image, etc.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Blocks`}</h2>
    <p>{`Block of action are modular components that define a specific intent within a chatbot's flow. Each block represents a group of actions.`}</p>
    <h2>{`Profiles for external services`}</h2>
    <p>{`Profiles for external services in a chatbot platform refer to stored configurations that allow the chatbot to connect and interact with third-party services: OCR, NLP, Authentication, AI and Live Chat.`}</p>
    <h2>{`Global rules`}</h2>
    <p>{`Global rules are predefined conditions or logic that apply across multiple chatbot interactions, ensuring consistent behavior, automation, and compliance.`}</p>
    <h2>{`Goal list`}</h2>
    <p>{`A goal list outlines the key objectives a chatbot should achieve to enhance automation, user experience, and business efficiency.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      